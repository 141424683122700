import React from 'react'
import styled from 'styled-components'
import ContactButton from '../components/buttons/Contact'
import Layout from '../components/layout'

const Container = styled.div`
    width: 100%;

    .header{
        text-align: center;
        margin-bottom: 2.5rem;
    }

    form{

        input, textarea{
                margin: 0 auto;
                margin-bottom: 2.5rem;
                display: block;
            }

            input{
                min-height: 3rem;
                min-width: 60%;
            }

            label{
                margin-left: 20%;
                display: block;
            }

            textarea{
                min-width: 60%;
                margin-bottom: 5rem;
            }

            .group-buttons{
                margin-left: 20%;
                padding-top: 1rem;
            }

            .radio-group{
                display: inline-flex;
                width: 100px;
                margin: 0 auto;

                input{
                    min-height: 2rem;
                    min-width: 40%;
                }

                label{
                    font-size: 1.5rem;
                    width: 100px;
                    text-align: center;
                }
            }

            .services{
                width: 60%;
                margin: 0 auto;
                padding-top: 2rem;
                display: flex;
                align-self: center;
                flex-wrap: wrap;
                justify-content: space-around;

                input{
                    min-height: 2rem;
                    min-width: 40%;
                }

                .block{

                    label{
                        margin: 0 auto;
                    }

                    text-align: center;
                    width: 150px;
                }
            }
    }
`

const formWebApp = () => {
    return(
        <Layout>
            <Container>
                <div className="header">
                    <h3>New Client Questionaire</h3>
                </div>
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="new-client">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="new-client" />
                    <input type="text" name="name" id="name" placeholder="Business Name"/>
                    <input type="text" name="contact name" id="email" placeholder="Name of Main Contact" required/>
                    <input type="text" name="title" id="email" placeholder="Title"/>
                    <input type="tel" name="phone" id="phone" placeholder="Phone" required/>
                    <input type="email" name="email" id="email" placeholder="Email" required/>

                    <label>Expected Completion Date:</label>
                    <input type="text" name="completion date" placeholder="01-01-2022"/>

                    <label>Current Website(if applicable)</label>
                    <input type="text" name="website"/>

                    <label>Current Hosting Company(if applicable)</label>
                    <input type="text" name="host"/>

                    <label>Where is your Domain Registered?</label>
                    <input type="text" name="domain"/>

                    <label>If You Currently Have a Site, What Are The Main Reason You Are Wanting It Upgraded/Revamped/Redesigned?</label>
                    <textarea name="current site" id="message" rows="3" />

                    <label>Describe the desired look and feel by using adjectives and short phrases.</label>
                    <textarea name="look and feel" id="message" rows="3" />

                    <label>Describe your target audience? What do you want to your site to convey?</label>
                    <textarea name="audience" id="message" rows="3" />

                    <label>For Profit: Who are your direct competitors? Non Profit: Name some peer organizations that are similar to yours.</label>
                    <textarea name="competitors" id="message" rows="3" />

                    <label>List any important words that you want people to associate with your organization’s image and brand.</label>
                    <textarea name="competitors" id="message" rows="3" />

                    <label>If you were using a search engine, what words or phrases would you use to find your site?</label>
                    <textarea name="seo" id="message" rows="3" />

                    <label>Please list the names of three to five other sites that you like. In the comment section, please articulate why the sites are attractive to you.</label>

                    <input type="text" name="url1" id="phone" placeholder="URL #1" required/>
                    <textarea name="url 1 - details" id="message" placeholder="Comments" rows="3" />

                    <input type="text" name="url2" id="phone" placeholder="URL #2" required/>
                    <textarea name="urls 2 - details" id="message" placeholder="Comments" rows="3" />

                    <input type="text" name="url3" id="phone" placeholder="URL #3" required/>
                    <textarea name="urls 3 - details" id="message" placeholder="Comments" rows="3" />

                    <label>What types of things do you see on other websites that you hate?</label>
                    <textarea name="seo" id="message" rows="3" />

                    <label>Is your site managed by a content management system (CMS)?</label>
                        <div className="group-buttons">
                            <div className='radio-group'>
                                <label>yes</label>
                                <input type="radio" name="cms - yes" value="yes" className='radio-btn' />
                            </div>
                            <div className='radio-group'>
                                <label>no</label>
                                <input type="radio" name="cms - no" value="no" className='radio-btn'/>
                            </div>
                        </div>

                    <label>If Yes, which CMS?</label>
                    <input type="text" name="cms" id="phone" placeholder="CMS" required/>

                    <label>How Often Will You Be Updating Content?</label>
                    <input type="text" name="content" id="phone" required/>

                    <label>Which Content Do You Plan on Updating?</label>
                    <textarea name="seo" id="message" placeholder="blog posts, about sections, particular pages, etc" rows="3" />

                    <label>Select All Services You Are Interested In:</label>
                    <div className="services">
                        <div className="block">
                            <label>Web Development</label>
                            <input type="checkbox" name="Web Development" id="email"/>
                        </div>
                        <div className="block">
                            <label>App Development</label>
                            <input type="checkbox" name="App Development" id="email"/>
                        </div>
                        <div className="block">
                            <label>Custom Web Design</label>
                            <input type="checkbox" name="Web Design" id="email"/>
                        </div>
                        <div className="block">
                            <label>Branding</label>
                            <input type="checkbox" name="Branding" id="email"/>
                        </div>
                        <div className="block">
                            <label>Logo Design</label>
                            <input type="checkbox" name="Logo" id="email"/>
                        </div>
                        <div className="block">
                            <label>Blog</label>
                            <input type="checkbox" name="Blog" id="email"/>
                        </div>
                        <div className="block">
                            <label>CMS</label>
                            <input type="checkbox" name="cms" id="email"/>
                        </div>
                        <div className="block">
                            <label>Ecommerce</label>
                            <input type="checkbox" name="consulting" id="email"/>
                        </div>
                        <div className="block">
                            <label>SEO(Search Engine Optimazation)</label>
                            <input type="checkbox" name="seo" id="email"/>
                        </div>
                        <div className="block">
                            <label>Marketing</label>
                            <input type="checkbox" name="marketing" id="email"/>
                        </div>
                        <div className="block">
                            <label>Social Media Marketing</label>
                            <input type="checkbox" name="social media" id="email"/>
                        </div>
                        <div className="block">
                            <label>Team Training</label>
                            <input type="checkbox" name="team training" id="email"/>
                        </div>
                        <div className="block">
                            <label>Personal Training</label>
                            <input type="checkbox" name="training" id="email"/>
                        </div>
                        <div className="block">
                            <label>Analytics</label>
                            <input type="checkbox" name="analytics" id="email"/>
                        </div>
                        <div className="block">
                            <label>Video</label>
                            <input type="checkbox" name="video" id="email"/>
                        </div>
                        <div className="block">
                            <label>Photo</label>
                            <input type="checkbox" name="photo" id="email"/>
                        </div>
                        <div className="block">
                            <label>Hosting</label>
                            <input type="checkbox" name="hosting" id="email"/>
                        </div>
                        <div className="block">
                            <label>Update Existing Site</label>
                            <input type="checkbox" name="update" id="email"/>
                        </div>
                        <div className="block">
                            <label>Consulting</label>
                            <input type="checkbox" name="consulting" id="email"/>
                        </div>
                        <div className="block">
                            <label>Other:</label>
                            <input type="checkbox" name="other" id="email"/>
                        </div>
                    </div>

                    <label>If Other, Please Describe:</label>
                    <textarea name="other" id="message" placeholder="Please Describe..." rows="3" />

                    <label>Do You Currently Have Content?</label>
                        <div className="group-buttons">
                            <div className='radio-group'>
                                <label>yes</label>
                                <input type="radio" name="no" value=" content yes" className='radio-btn' />
                            </div>
                            <div className='radio-group'>
                                <label>no</label>
                                <input type="radio" name="yes" value=" content no" className='radio-btn'/>
                            </div>
                        </div>

                        <label>Planned Budget:</label>
                        <input type="text" name="budget"/>

                        <label>Additional Information:</label>
                        <textarea name="additional" id="message" rows="3" />

                    <ContactButton type="submit" color="black" title="SUBMIT" />
                </form>
            </Container>
        </Layout>
        )
}

export default formWebApp;